import React from "react"
import LegalNotices from "../../components/pages/LegalNotices"
import { graphql } from "gatsby"

const PrivacyPolicy = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_es,
    titleSeo: seoDataJson.title_es,
    description: seoDataJson.description_es,
    alternateLanguage: seoDataJson.alternateLanguage_es,
    alternateUrl: seoDataJson.alternateUrl_es,
  }
  const lang = "es"

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "LEGAL"
  })

  return (
    <LegalNotices lang={lang} seoData={seoData} urls={resultUrls} />
  )
}

export const pageQuery = graphql`
  query legalNoticesEsQuery {
    seoDataJson(name: { eq: "legal" }) {
      url_es
      title_es
      description_es
      alternateLanguage_es
      alternateUrl_es
    }
  }
`

export default PrivacyPolicy
